import 'grapesjs/dist/css/grapes.min.css'
import plugin from 'grapesjs-preset-webpage'
import tailwindPlugin from './plugins/tailwindPlugin'

const storeManager = (id?: string, token?: string) => ({
  type: 'remote',
  // id: 'gjs-' + id,
  autosave: true,
  stepsBeforeSave: 1,
  options: {
    remote: {},
  },
  storeComponents: true,
  storeStyles: true,
  storeHtml: true,
  storeCss: true,
})
// https://staging.landing.acsop.epic-africa.org/login
const traitManager = {
  appendTo: '#trait-container',
}

const EditorConfig = () => {
  return {
    // Indicate where to init the editor. You can also pass an HTMLElement
    container: '#gjs',
    // Get the content for the canvas directly from the element
    // As an alternative we could use: `components: '<h1>Hello World Component!</h1>'`,
    fromElement: true,
    // Size of the editor
    height: '100vh',
    width: '100%',
    plugins: [plugin, tailwindPlugin],
    pluginsOpts: {
      plugins: [
        (editor: any) =>
          tailwindPlugin(editor, {
            blocks: ['Hero', 'Header'],
          }),
      ],
      flexGrid: 1,
      // blocks: ['link-block', 'quote', 'text-basic'],
    },
    // Disable the storage manager for the moment

    storageManager: storeManager(),
    assetManager: {
      assets: [
        'http://placehold.it/350x250/78c5d6/fff/image1.jpg',
        // Pass an object with your properties
        {
          type: 'image',
          src: 'http://placehold.it/350x250/459ba8/fff/image2.jpg',
          height: 350,
          width: 250,
          name: 'displayName',
        },
        {
          // As the 'image' is the base type of assets, omitting it will
          // be set as `image` by default
          src: 'http://placehold.it/350x250/79c267/fff/image3.jpg',
          height: 350,
          width: 250,
          name: 'displayName',
        },
      ],
      // upload: false,
    },

    layerManager: {
      appendTo: '#layers-container',
    },
    deviceManager: {
      devices: [
        {
          name: 'Desktop',
          width: '', // default size
        },
        {
          name: 'Mobile',
          width: '320px', // this value will be used on canvas width
          widthMedia: '480px', // this value will be used in CSS @media
        },
        {
          name: 'Tablet',
          width: '768px', // this value will be used on canvas width
          widthMedia: '810px', // this value will be used in CSS @media
        },
      ],
    },

    // We define a default panel as a sidebar to contain layers
    panels: {
      defaults: [
        {
          id: 'panel-switcher',
          el: '.panel__switcher',
          buttons: [
            {
              id: 'show-layers',
              active: true,
              label: 'Layers',
              command: 'show-layers',
              // Once activated disable the possibility to turn it off
              togglable: false,
            },
            {
              id: 'show-style',
              active: true,
              label: 'Styles',
              command: 'show-styles',
              togglable: false,
            },
            {
              id: 'show-traits',
              active: true,
              label: 'Traits',
              command: 'show-traits',
              togglable: false,
            },
          ],
        },
        {
          id: 'panel-devices',
          el: '.panel__devices',
          buttons: [
            {
              id: 'device-desktop',
              command: 'set-device-desktop',
              className: 'fa-desktop',
              active: true,
              togglable: false,
            },
            {
              id: 'device-tablet',
              className: 'fa-tablet',
              command: 'set-device-tablet',
              togglable: false,
            },
            {
              id: 'device-mobile',
              className: 'fa-mobile',
              command: 'set-device-mobile',
              togglable: false,
            },
          ],
        },
      ],
    },
    traitManager: traitManager,
    // The Selector Manager allows to assign classes and
    // different states (eg. :hover) on components.
    // Generally, it's used in conjunction with Style Manager
    // but it's not mandatory
    selectorManager: {
      appendTo: '.styles-container',
    },
    styleManager: {
      appendTo: '#styles-container',
      // sectors: [
      //   {
      //     name: 'Dimension',
      //     open: false,
      //     // Use built-in properties
      //     // buildProps: ['width', 'min-height', 'padding','display','color'],
      //     // Use `properties` to define/override single property
      //     properties: [
      //       {
      //         // Type of the input,
      //         // options: integer | radio | select | color | slider | file | composite | stack
      //         type: 'integer',
      //         name: 'The width', // Label for the property
      //         property: 'width', // CSS property (if buildProps contains it will be extended)
      //         units: ['px', '%', 'em', 'rem'], // Units, available only for 'integer' types
      //         defaults: 'auto', // Default value
      //         min: 0, // Min value, available only for 'integer' types
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Extra',
      //     open: false,
      //     buildProps: [
      //       'background-color',
      //       'box-shadow',
      //       'custom-prop',
      //       'transform',
      //       'color',
      //     ],
      //     properties: [
      //       {
      //         id: 'custom-prop',
      //         name: 'Custom Label',
      //         property: 'font-size',
      //         type: 'select',
      //         defaults: '32px',
      //         // List of options, available only for 'select' and 'radio'  types
      //         options: [
      //           { value: '12px', name: 'Tiny' },
      //           { value: '18px', name: 'Medium' },
      //           { value: '32px', name: 'Big' },
      //         ],
      //         onchange: (event: any) => {
      //           console.log(event, 'ewfefewrfgewf')
      //         },
      //       },
      //     ],
      //   },
      // ],
    },
  }
}

export default EditorConfig
